.ink-press {
  width: 100%;

  .press-img {
    width: 200px;
  }

  .press-view {
    padding: 0 20px;
    margin-bottom: 5px;
    border-bottom: 2px solid #efefef;
    border-radius: 3px;
  }

  .press-view h3 a {
    color: #555;
  }

  // TODO: This definition is in index.scss, but it's not being applied
  h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #555;
  }
}
